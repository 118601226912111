import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroSupport from "./../../components/ServiceSinglePage/Hero/Support/HeroSupport"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"

const heroTextTitle = "Support & Maintenance"
const heroTextSmallText =
    "Once your website is live, it’s only the start of the journey. You will be in good hands with Saigon Digital. Protect your investment with support and retainer services that help you grow, fine tune and scale your website."
const ctaTitle = ""
const heroData = [
    "Saigon Digital support and maintenance packages are an ideal way to guarantee your team has a support partner that cares and can offer dedicated support hours for your website when you need it",
    "Our maintenance, support & continuous improvement retainers offer our clients ultimate peace of mind that their site is running smoothly, securely and ready to scale when required. ",
    "Oh, and we’re also a friendly bunch to talk to.",
    "New features? Technical advice or guidance? How about a new section or template on your website? No problem at all. We’re here to listen, support and guide you in any way for your project. ",
    "We provide fast, effective and flexible development resources which are focused on prompt but valuable response in line with our Service Level Agreement.",
    "We have retainer packages to suit clients of all sizes and needs, reach out to us today to see how we can help you and your project."
]

const iconBlockTitle = "An approachable and friendly development team"
const icons = [
    {
        name: "team",
        title: "Team Loyalty",
        text: "A team that cares. No more flaky developers that quit responding to emails. You’ll now have a dedicated developer and account / project manager that you can trust and rely on."
    },
    {
        name: "code",
        title: "Code Assessments",
        text: "Is your website/app not running as smoothly as it should? We perform technical code reviews that will inspect your codebase, analysing any performance issues and suggest improvements, point out security flaws etc."
    },
    {
        name: "computer",
        title: "Data Migration",
        text: "Want to move your website from one place to another? Relax, knowing it will be handled by an experienced professional."
    },
    {
        name: "shield",
        title: "Security",
        text: "Never worry about website updates again. We ensure that our code bases and platforms are running the latest and greatest at all times. Focus on the things that matter. Let us handle the small things."
    },
    {
        name: "tool",
        title: "Testing and Bug Fixing",
        text: "As your website grows there may be times where issues appear and bug fixing will be required. We will help address and advise any bug fixes and issues that crop up along the way as part of our maintenance."
    }
]
const cta1 = {
    title: "Grow and scale your project",
    description:
        "We understand you’ve got a lot to do in your business other than working on your website. Saigon Digital’s support service is set up to help you focus on running your organisation or marketing activities, leaving us to do what we do best: taking the tech off your mind."
}
const cta2 = {
    title: "Looking for an agency support partner? Reach out for a chat today."
}

const Jamstack = ({ location }) => {
    const context = {
        pageName: "Service | Jamstack Development",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroSupport
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="support"
                    src="../../components/ServiceSinglePage/images/support-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.supportFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Jamstack
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why should I choose Saigon Digital for website support and maintenance services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital provides tailored solutions that ensure your website remains secure, optimised, and up-to-date. With a personalised approach, we cater to your specific business needs, making sure your website runs smoothly and efficiently."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is included in your web maintenance package?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Our web maintenance package includes CMS updates, plugin management, and security enhancements. We also offer performance optimisation, content updates, regular backups, and technical support, ensuring your website remains secure and efficient."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How often should I use a maintenance service for my website?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Regular maintenance service is vital. We recommend monthly updates for most tasks, with weekly security checks for high-traffic or sensitive sites to ensure optimal performance and safety."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why is a web maintenance package essential?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "A web maintenance package is crucial for preventing security issues, maintaining technological compatibility, and keeping your site running smoothly. It also supports better SEO rankings and user experience."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Can you update content and design as part of the support package?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Yes, our support package covers content updates and minor design changes. This keeps your site current, visually appealing, and aligned with SEO best practices."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How do you ensure website security and backups in your maintenance service?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Our maintenance service includes robust security measures like firewalls and regular backups. We also monitor your website to prevent and address any issues promptly."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What happens if my website experiences downtime?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "All of our clients' websites have a 99% uptime through our partner Netlify. In the even that your website goes down, our support package ensures rapid response to restore it, minimising any disruption to your business."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Can you maintain a website developed by someone else?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Yes, we can maintain websites built by others. We start with a detailed site audit, then apply our WordPress maintenance package or web design maintenance package to keep your site secure and updated."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "Support Services | Tailored Support Development | Saigon Digital"
            }
            description={
                "Protect your investment with support and retainer services that help you grow, fine tune and scale your website."
            }
        />
    </>
)
